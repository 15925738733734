import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

class CimsUserList {
    /**
     * 생성자
     */
    constructor() {
        this.curPage = 1;
        this.topSize = 2147483647;
    }

    /**
     * cims 사용자 목록 조회
     * @param {Any} parameters 
     * @returns 
     */
    getUserList(parameters) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.UserInfoBiz';
            request.methodId = 'GetCIMSUserList';
            request.parameters.category = parameters.category
            request.parameters.keyword = parameters.keyword
            request.parameters.curPage = parameters.curPage
            request.parameters.topSize = parameters.topSize
            request.parameters.isDelete = parameters.isDelete

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }
}
export const cimsUserList = new CimsUserList();