<template>
    <div class="admin-content">
		<h3>사용자 관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
              <div class="btn-wrap">
                <button class="btn-box" @click="methods.getCimsUserList">조회</button>
                <button class="btn-box green" @click="methods.moveDtls">등록</button>
              </div>
            </div>
            <div>
				<div class="sch-wrap3">
					<div>
						<!-- 검색 조건 -->
                        <div class="select-box">
                            <select v-model="state.parameter.category">
                                <option value="U">성명</option>
                                <option value="I">아이디</option>
                                <option value="P">사업장명</option>
                            </select>
                        </div>
					</div>
					<div>
						<div>
						    <!-- 검색어 -->
                            <input type="text" class="input-text mr10" v-model="state.parameter.keyword" @keyup.enter="methods.getCimsUserList" />
						</div>
					</div>
					<div>
						<div class="chk-box">
                            <!-- 삭제된 사용자 포함 여부 -->
                            <input type="checkbox" id="chkUseYn" v-model="state.dataTable.chkUseYn" :binary="true" @change="eventHandler.dataTable.changeUseYn"/>
                            <label for="chkUseYn"><p>삭제된 사용자 포함</p></label>
                        </div>
					</div>
				</div>
			</div>
        </div>

        <!-- 총 개수 -->
		<div class="total-box">
			<p>Total <strong>{{state.dataTable.rowCount}}</strong></p>
		</div>

        <DataTable 
            class="p-datatable-sm"
            scrollHeight="497px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            columnResizeMode="fit"
            :value="state.dataTable.cimsUserItems"
            :paginator="true"
            :rowHover="true"
            :rows="15"
            :resizableColumns="true"
            @row-click="eventHandler.dataTable.onRowClick">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="EmplID" header="아이디" style="width:300px" headerClass="tc"></Column>
            <Column field="EmplName" header="성명" style="width:300px" headerClass="tc"></Column>
            <Column field="pname" header="사업장명" style="width:900px" headerClass="tc">
                <template #body="{data}">
                        <p class="text-overflow2">
                            {{data.pname}}
                        </p>
                </template>
            </Column>
            <Column field="UseYN" header="사용여부" :style="{'text-align':'center', 'width' : '300px'}" headerClass="tc"></Column>
            <Column field="regDate" header="등록일" :style="{'text-align':'center', 'width' : '300px'}" headerClass="tc"></Column>

        </DataTable>

    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { cimsUserList } from '@/modules/cims/CIM_CM0130E.js';
import router from '@/router'
export default {
    setup() {

        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();
        
        // state
        const state = reactive({
            parameter: {
                // 카테고리
                category : "U",

                // 검색 키워드
                keyword : null,

                // 현재 페이지
                curPage : cimsUserList.curPage,

                // 최대 사이즈
                topSize : cimsUserList.topSize,

                // 삭제된 사용자 포함 여부
                isDelete : "N",
            },
            dataTable: {
                // 사용자 정보
                cimsUserItems : null,

                // 삭제된 사용자 체크
                chkUseYn : false,

                // 행 개수
                rowCount : 0,
            },
        });

        // 메서드
        const methods = {

            // 사용자 조회 메서드
            getCimsUserList: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                cimsUserList.getUserList(state.parameter).then(result => {

                    state.dataTable.cimsUserItems = result;
                    state.dataTable.rowCount = result.length;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            
            // 신규 등록 화면으로 이동
            moveDtls: () => {

                router.push({ name: 'CIM_CM0131E', query: null });
            }
        }

        const eventHandler = {

            dataTable: {

                // 삭제한 사용자 포함여부 변경 이벤트
                changeUseYn: () => {

                    if (state.dataTable.chkUseYn) state.parameter.isDelete = "Y"
                    else state.parameter.isDelete = "N"
                },

                // 행 클릭 이벤트
                onRowClick: (event) => {
                    
                    var q = vrt.query;
                    q["EmplID"] = event.data.EmplID;
                    router.push({ name: 'CIM_CM0131E', query: q });
                }
            }
        }

        onMounted(() => {

            // 사용자 정보 초기조회
            methods.getCimsUserList();
        })

        return {
            state,
            cimsUserList,
            methods,
            eventHandler,
        }
    }
}
</script>